import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//import {Link} from "react-router-dom";

class Categories extends Component {
  render() {
    return (
      <div className="container-fluid pt-2">
        <NavLink to="/all-articles">All</NavLink> | {" "}
        <NavLink to="/landscape-design">Landscape Design</NavLink> | {" "}
        <NavLink to="/how-to-plant">How To Plant</NavLink> | {" "}
        <NavLink to="/premier-garden-care">Premier Garden Care</NavLink> | {" "}
        <NavLink to="/mulches-soil-amendments">Mulches Soil Amendments</NavLink> | {" "}
        <NavLink to="/our-guarantee">Our Guarantee</NavLink> | {" "}
        <NavLink to="/locally-grown">Locally Grown</NavLink> | {" "}
        <NavLink to="/all-the-fall-things">All The Fall Things</NavLink> | {" "}
        <NavLink to="/pottery-garden">Pottery Garden</NavLink> |{" "}
        <NavLink to="/christmas">Christmas</NavLink> | {" "}
        <NavLink to="/container-design">Container Design</NavLink> | {" "}
        <NavLink to="/our-plants">Our Plants</NavLink>
      </div>
    );
  }
}

export default Categories;
