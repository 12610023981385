import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings

class HoneyBees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      honeybeespage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "honeybees",
    }).then((entries) => {
      this.setState({ honeybeespage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(honeybeesDescription) {
    return {
      __html: marked(honeybeesDescription, { sanitize: false }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <div className="container-fluid">
        {this.state.honeybeespage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.honeybeespage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-2">
                    <h1 className="text-uppercase">{item.fields.honeybeesTitle}</h1>
                    <h2 className="lead">{item.fields.honeybeesShortText}</h2>
                  </div>

                  <div className="pt-2">
                    <div className="row">
                      <div className="col-lg-4">
                        <img
                          className="img-fluid"
                          src={item.fields.honeybeesImg.fields.file.url}
                          alt="icon"
                        />
                      </div>
                      <div className="col-lg-8">
                        <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.honeybeesDescription
                        )}></div>
                      </div>
                      <img
                          className="img-fluid"
                          src={item.fields.honeybeesImg2.fields.file.url}
                          alt="icon"
                        />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default HoneyBees;
