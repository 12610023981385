import React, { Component } from 'react'
import Client from '../Client'
import BlackLoading from '../images/black-loader.gif';
import {Carousel} from 'react-bootstrap';

class FallFunFlashImages extends Component {
  constructor(props) {
    super(props)
    this.state = {
         fallfunflashimgpage:[]
    }
}

componentDidMount(){
    Client.getEntries({
        'content_type': 'fallfunFlashImages',

    }).then((entries)=>{
        this.setState({fallfunflashimgpage: entries.items})
    });
}


render() {
  return (
    <>
        {this.state.fallfunflashimgpage.length === 0 ?
              <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
        <Carousel fade={false}>
          
          {this.state.fallfunflashimgpage.map((item, index) => {
            return(
            <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.fields.fallfunFlashImagesImg.fields.file.url}
              alt="sam bridge fall fun slide"
            />
            <Carousel.Caption>
                <span className="slidefont">{item.fields.fallfunFlashImagesTitle}</span>
            </Carousel.Caption>
            </Carousel.Item>
                  )
                  })}
        </Carousel>
        }

    </>
    )
  }
}

export default FallFunFlashImages;


