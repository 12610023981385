import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings
import FreshCutChristmasTreesGallery from "./FreshCutChristmasTreesGallery";

class FreshCutChristmasTrees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freshCutChristmasTreespage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "freshCutChristmasTrees",
    }).then((entries) => {
      this.setState({ freshCutChristmasTreespage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(freshCutChristmasTreesDescription) {
    return {
      __html: marked(freshCutChristmasTreesDescription, { sanitize: false }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <>
      <div className="container-fluid">
        {this.state.freshCutChristmasTreespage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.freshCutChristmasTreespage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-2">
                    <h1 className="text-uppercase">{item.fields.freshCutChristmasTreesTitle}</h1>
                    {/* <h2 className="lead">{item.fields.freshCutChristmasTreesShortDetails}</h2> */}
                  </div>

                  <div className="pt-2">
                    <div className="row">
                      {/* <div className="col-lg-4">
                        <img
                          className="img-fluid"
                          src={item.fields.freshCutChristmasTreesImg.fields.file.url}
                          alt="icon"
                        />
                        
                      </div> */}
                      <div className="col-lg-8">
                        {/* <p>{item.fields.aboutDescription}</p> */}
                        <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.freshCutChristmasTreesDescription
                        )}></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="pt-4">
            <FreshCutChristmasTreesGallery/>
      </div>
      </>
    );
  }
}

export default FreshCutChristmasTrees;
