import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings

class FarmersmarketWeeklyVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farmersmarketWeeklyVendorspage: [],      
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "farmersmarketWeeklyVendors",
    }).then((entries) => {
      this.setState({ farmersmarketWeeklyVendorspage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(farmersmarketWeeklyVAddress) {
    return {
      __html: marked(farmersmarketWeeklyVAddress, { sanitize: true }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <div>
        {this.state.farmersmarketWeeklyVendorspage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.farmersmarketWeeklyVendorspage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-4">
                    <h1>{item.fields.farmersmarketWeeklyVTitle}</h1>
                    {/* <p>{item.fields.farmersmarketDescription}</p> */}
                    <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.farmersmarketWeeklyVAddress
                        )}>
                  </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default FarmersmarketWeeklyVendors;
