import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";

class TheSamBridgeChristmasShopGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theSamBridgeChristmasShopGallerypage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "theSamBridgeChristmasShopGallery",
      //'order': 'sys.createdAt'
    }).then((entries) => {
      this.setState({ theSamBridgeChristmasShopGallerypage: entries.items});
    });
  }


  render() {
    return (
      <div className="container-fluid">
        {this.state.theSamBridgeChristmasShopGallerypage.length === 0 ?
          <div><img src={BlackLoading} alt="Loading..." /></div> :
              <div className="grid">
                  {this.state.theSamBridgeChristmasShopGallerypage.map((item, num=0) => {
                      num = num + 1
                      return (
                          <div key={num} className="col-md-4 col-12">
                              <input type="checkbox" id={num} />
                              <label htmlFor={num} className="lightbox"><img src={item.fields.theSamBridgeChristmasShopGalleryPhoto.fields.file.url} alt="sam bridge christmas photos" /></label>
                              <label htmlFor={num}><img src={item.fields.theSamBridgeChristmasShopGalleryPhoto.fields.file.url} alt="sam bridge christmas photos" /></label>
                              {/* <p className="text-center">{item.fields.theSamBridgeChristmasShopGalleryTitle}</p> */}
                          </div>
                      )
                  })}
            </div>
      }

      </div>
    );
  }
}

export default TheSamBridgeChristmasShopGallery;
