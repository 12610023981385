import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { HamburgerMenu } from "../hamburgerMenu";
import sambridgelogo from '../../images/sambridge-logo.png'
import {Link} from 'react-router-dom'

const NavbarContainer = styled.div`
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 0.5em;
`;

export function Navbar(props) {
  return (
    <>
    <NavbarContainer>
    
      <HamburgerMenu />
      <Container className="text-center"><Link to="/"><img src={sambridgelogo} alt=""/></Link></Container>
    </NavbarContainer>
    </>
  );
}
