import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings
import {Link} from 'react-router-dom';
import ContainerDesignFlashImages from "./ContainerDesignFlashImages";
//import HomeVideo from './HomeVideo';


class ContainerDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerdesignpage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "containerdesignNew",
    }).then((entries) => {
      this.setState({ containerdesignpage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(containerdesignDescription) {
    return {
      __html: marked(containerdesignDescription, { sanitize: false }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <>
      <div className="container-fluid">
        {this.state.containerdesignpage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.containerdesignpage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-2">
                    <h1 style={{textTransform: "uppercase"}}>{item.fields.containerdesignTitle}</h1>
                    <h2 className="lead">{item.fields.containerdesignShortDetails}</h2>
                  </div>

                  <div className="pt-2">
                    <div className="row">
                      <div className="col-lg-4">
                        <img
                          className="img-fluid"
                          src={item.fields.containerdesignImg.fields.file.url}
                          alt="container design"
                        />
                        
                      </div>
                      <div className="col-lg-8">
                        {/* <p>{item.fields.aboutDescription}</p> */}
                        <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.containerdesignDescription
                        )}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

                {/* <div>
                    <h1 style={{"text-align":"center"}}>Looking for a Unique Gift Ideas?</h1>
                    <HomeVideo/>
                </div> */}

          <div className="pt-4">
            <Link to="/container-design-program-gallery"><ContainerDesignFlashImages/></Link>  
          </div>

      {/* <div className="pt-4">
      <Link to="/container-design-program-gallery"><button className="btn btn-primary">View Gallery</button></Link>
      </div> */}
      </div>
      
      </>
    );
  }
}

export default ContainerDesign;
