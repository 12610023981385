import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import {Link} from 'react-router-dom';

class LocallyGrownGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locallygrownGallerypage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "locallygrownGallery",
      //'order': 'sys.createdAt'
    }).then((entries) => {
      this.setState({ locallygrownGallerypage: entries.items});
    });
  }

  redirectToTarget=()=>{
    this.props.history.push('/locally-grown')
  }


  render() {
    return (
    <>
      <div className="container-fluid text-left">
        <h1>LOCALLY GROWN GALLERY</h1>
      </div>
      <div>
      
        {this.state.locallygrownGallerypage.length === 0 ?
          <div><img src={BlackLoading} alt="Loading..." /></div> :
              <div className="grid">
                  {this.state.locallygrownGallerypage.map((item, num=0) => {
                      num = num + 1
                      return (
                          <div key={num} className="col-md-4 col-12">
                              <input type="checkbox" id={num} />
                              <label htmlFor={num} className="lightbox"><img src={item.fields.locallygrownGalleryPhoto.fields.file.url} alt="sam bridge locally grown photos" /></label>
                              <label htmlFor={num}><img src={item.fields.locallygrownGalleryPhoto.fields.file.url} alt="sam bridge locally grown photos" /></label>
                              {/* <p className="text-center">{item.fields.locallygrownGalleryTitle}</p> */}
                          </div>
                      )
                  })}
            </div>
      }

      

      </div>
      <div className="container-fluid">
      <p><Link to="#" onClick={this.redirectToTarget}><button className="btn btn-primary">Back to Locally Grown</button></Link></p>
      </div>
    </>
    );
  }
}

export default LocallyGrownGallery;
