import React, { Component } from 'react'
import Client from '../Client'
import BlackLoading from '../images/black-loader.gif';
import {Carousel} from 'react-bootstrap';

class TreesShrubsFlashImages extends Component {
  constructor(props) {
    super(props)
    this.state = {
         TreesShrubsFlashImgpage:[]
    }
}

componentDidMount(){
    Client.getEntries({
        'content_type': 'treesShrubsFlashImages',

    }).then((entries)=>{
        this.setState({TreesShrubsFlashImgpage: entries.items})
    });
}


render() {
  return (
    <>
        {this.state.TreesShrubsFlashImgpage.length === 0 ?
              <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
        <Carousel fade={false}>
          
          {this.state.TreesShrubsFlashImgpage.map((item, index) => {
            return(
            <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.fields.treesShrubsFlashImg.fields.file.url}
              alt="sam bridge pottery garden supplies slide"
            />
            <Carousel.Caption>
                <span className="slidefont">{item.fields.treesShrubsFlashTitle}</span>
            </Carousel.Caption>
            </Carousel.Item>
                  )
                  })}
        </Carousel>
        }

    </>
    )
  }
}

export default TreesShrubsFlashImages;


