import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings
import TheSamBridgeChristmasShopGallery from "./TheSamBridgeChristmasShopGallery";

class TheSamBridgeChristmasShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theSamBridgeChristmasShoppage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "theSamBridgeChristmasShop",
    }).then((entries) => {
      this.setState({ theSamBridgeChristmasShoppage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(theSamBridgeChristmasShopDescription) {
    return {
      __html: marked(theSamBridgeChristmasShopDescription, { sanitize: false }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <>
      <div className="container-fluid">
        {this.state.theSamBridgeChristmasShoppage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.theSamBridgeChristmasShoppage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-2">
                    <h1 className="text-uppercase">{item.fields.theSamBridgeChristmasShopTitle}</h1>
                    {/* <h2 className="lead">{item.fields.theSamBridgeChristmasShopShortDetails}</h2> */}
                  </div>

                  <div className="pt-2">
                    <div className="row">
                      {/* <div className="col-lg-4">
                        <img
                          className="img-fluid"
                          src={item.fields.theSamBridgeChristmasShopImg.fields.file.url}
                          alt="icon"
                        />
                        
                      </div> */}
                      <div className="col-lg-8">
                        {/* <p>{item.fields.aboutDescription}</p> */}
                        <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.theSamBridgeChristmasShopDescription
                        )}></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="pt-4">
      <TheSamBridgeChristmasShopGallery/>
      </div>
      </>
    );
  }
}

export default TheSamBridgeChristmasShop;
