import React from 'react';
import './App.css';
import {Route,Switch} from "react-router-dom";
import Home from './Pages/Home';
//import About from './Pages/About';
//import Contact from './Pages/Contact';
import Footer from './Components/Footer';
import Error from './Pages/Error';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import FarmersMarket from './Pages/FarmersMarket';
//import Ourexperts from './Pages/Ourexperts';
import Watering from './Pages/Watering';
import Careers from './Pages/Careers';
import CareersDetails from './Pages/CareersDetails';
import LandscapeDesign from './Pages/LandscapeDesign';
import OurPlants from './Pages/OurPlants';
import PremierGardenCare from './Pages/PremierGardenCare';
import MulchesSoilAmendments from './Pages/MulchesSoilAmendments';
//import OurGuarantee from './Pages/OurGuarantee';
import LocallyGrown from './Pages/LocallyGrown';
import PotteryGarden from './Pages/PotteryGarden';
import Christmas from './Pages/Christmas';
import ContainerDesign from './Pages/ContainerDesign';
import HoneyBees from './Pages/HoneyBees';
import AllArticles from './Pages/AllArticles';
import { Navbar } from "./Components/navbar";
//import PlantingAfterCare from './Pages/PlantingAfterCare';
import FallFun from './Pages/FallFun';
//import GreenwichReindeerFestival from './Pages/GreenwichReindeerFestival';
import LocallyGrownGallery from './Pages/LocallyGrownGallery';
import LandscapeDesignGallery from './Pages/LandscapeDesignGallery';
import PremierGardenCareGallery from './Pages/PremierGardenCareGallery';
import ContainerDesignGallery from './Pages/ContainerDesignGallery';
import FallThingsGallery from './Pages/FallThingsGallery';
import OurexpertsGallery from './Pages/OurexpertsGallery';
import PotteryGardenGallery from './Pages/PotteryGardenGallery';
import FreshCutChristmasTrees from './Pages/FreshCutChristmasTrees';
import WreathsGreensRopingCenterpieces from './Pages/WreathsGreensRopingCenterpieces';
import LocallyGrownHolidayPlants from './Pages/LocallyGrownHolidayPlants';
import TheSamBridgeChristmasShop from './Pages/TheSamBridgeChristmasShop';
import PagesDetails from './Pages/PagesDetails';
import TreesShrubs from './Pages/TreesShrubs';
import TreesShrubsGallery from './Pages/TreesShrubsGallery';
import MasonryServices from './Pages/MasonryServices';
import MasonryServicesGallery from './Pages/MasonryServicesGallery';



function App() {
  return (
      <div>
        <Navbar />
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={2000} classNames="fade">
              <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route exact path="/all-articles" component={AllArticles} />
              {/* <Route exact path="/about" component={About}/> */}
              <Route exact path="/farmers-market" component={FarmersMarket}/>
              {/* <Route exact path="/our-experts" component={Ourexperts}/> */}
              <Route exact path="/our-experts-gallery" component={OurexpertsGallery}/>
              <Route exact path="/watering" component={Watering}/>
              <Route exact path="/honey-bees" component={HoneyBees}/>
              <Route exact path="/careers" component={Careers}/>
              <Route exact path="/careers-details/:slug" component={CareersDetails}/>
              <Route exact path="/our-plants" component={OurPlants}/>
              <Route exact path="/landscape-design-installation" component={LandscapeDesign}/>
              <Route exact path="/landscape-design-gallery" component={LandscapeDesignGallery}/>
              {/* <Route exact path="/planting-aftercare" component={PlantingAfterCare}/> */}
              <Route exact path="/premier-garden-care" component={PremierGardenCare}/>
              <Route exact path="/premier-garden-care-gallery" component={PremierGardenCareGallery}/>
              <Route exact path="/mulches-soil-amendments" component={MulchesSoilAmendments}/>
              {/* <Route exact path="/our-guarantee" component={OurGuarantee}/> */}
              <Route exact path="/locally-grown" component={LocallyGrown}/>
              <Route exact path="/locally-grown-gallery" component={LocallyGrownGallery}/>
              <Route exact path="/fall-fun" component={FallFun}/>
              <Route exact path="/fall-fun-gallery" component={FallThingsGallery}/>
              {/* <Route exact path="/greenwich-reindeer-festival" component={GreenwichReindeerFestival}/> */}
              <Route exact path="/pottery-garden" component={PotteryGarden}/>
              <Route exact path="/pottery-garden-gallery" component={PotteryGardenGallery}/>
              <Route exact path="/christmas" component={Christmas}/>
              <Route exact path="/container-design-program" component={ContainerDesign}/>
              <Route exact path="/container-design-program-gallery" component={ContainerDesignGallery}/>
              {/* <Route exact path="/contact" component={Contact}/> */}
              <Route exact path="/christmas" component={Christmas}/>
              <Route exact path="/fresh-cut-christmas-trees" component={FreshCutChristmasTrees}/>
              <Route exact path="/wreaths-greens-roping-centerpieces" component={WreathsGreensRopingCenterpieces}/>
              <Route exact path="/locally-grown-holiday-plants" component={LocallyGrownHolidayPlants}/>
              <Route exact path="/the-sam-bridge-christmas-shop" component={TheSamBridgeChristmasShop}/>
              <Route exact path="/trees-shrubs" component={TreesShrubs}/>
              <Route exact path="/trees-shrubs-gallery" component={TreesShrubsGallery}/>
              <Route exact path="/masonry-services" component={MasonryServices}/>
              <Route exact path="/masonry-services-gallery" component={MasonryServicesGallery}/>
              <Route exact path="/:slug" component={PagesDetails}/>

              {/* Wreaths, Greens, Roping & Centerpieces */}
              <Route component={Error}/>
            </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />

        <Footer/>
      </div>
  );
}

export default App;
