import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../images/black-loader.gif'

class CareersDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             careeropportunitiespage:[]
        }
    }

    componentDidMount(){
        const slug = this.props.match.params.slug
        if(slug){
            Client.getEntries({
                'content_type': 'careeropportunities', 
                'fields.slug': slug
    
            }).then((entries)=>{
                this.setState({careeropportunitiespage: entries.items[0]})
            })

        }
        
    }

    //to make font bold and headings
    getParsedMarkdown(careeropportunitiesDescription){
        return{
            __html:marked(careeropportunitiesDescription, {sanitize:false})
        }
    }
    //to make font bold and headings - end
    
    redirectToTarget=()=>{
        this.props.history.push('/careers')
    }
    render() {
        return (
            <div className="container-fluid pt-5">
                <div className="row">
                <div className="col-12">
                {this.state.careeropportunitiespage.length === 0 ?
                    <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
                <div className="row">
                <div className="col-md-12">
                
                    {/* <img src={this.state.careeropportunitiespage.fields.fallthingsBigImg.fields.file.url} className="img-fluid" alt="{blogTitle}" style={{float:'left', margin:'0 10px 0 0'}}/> */}
                    <h1 align="left">{this.state.careeropportunitiespage.fields.careeropportunitiesTitle}</h1>
                    <p style={{textAlign:'justify'}} dangerouslySetInnerHTML = 
                    {this.getParsedMarkdown(this.state.careeropportunitiespage.fields.careeropportunitiesDescription)}></p>
                    
                    
                </div>
                </div>
                }
                </div>
            </div>
            <p><button className="btn btn-primary"><Link to="#" onClick={this.redirectToTarget}>Back to Careers</Link></button></p>
            </div>
        )
    }
}

export default CareersDetails;
