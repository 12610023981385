import React, { Component } from 'react'
import Client from '../Client'
import BlackLoading from '../images/black-loader.gif'
import marked from 'marked' //to make font bold and headings
import {Link} from 'react-router-dom';

class Careers extends Component {
    constructor(props) {
        super(props)
        this.state = {
             careeropportunitiespage:[],
             careerspage:[],
        }
    }

    componentDidMount(){
        Client.getEntries({
            content_type: "careers",
          }).then((entries) => {
            this.setState({ careerspage: entries.items });
          });

        Client.getEntries({
            'content_type': 'careeropportunities',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({careeropportunitiespage: entries.items})
        })
    }

    //to make font bold and headings
    getParsedMarkdown(careersDescription) {
        return {
        __html: marked(careersDescription, { sanitize: false }),
        };
    }
    //to make font bold and headings - end

   
    render() {
        let CareersList = this.state.careeropportunitiespage.map((val, i) => {
            return(
                <div className="col-lg-6 col-md-4 col-12 pt-2" key={i}>
                    <div className="service-grid-item service-item">
                    {/* <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/careers-details/${val.fields.slug}`}>
                            <img src={val.fields.fallthingsImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div> */}
                    <div className="service-grid-item__content">
                        <h3>
                        <Link to={`${process.env.PUBLIC_URL}/careers-details/${val.fields.slug}`}>{val.fields.careeropportunitiesTitle}</Link>
                        </h3>
                        <p className="subtitle">{val.fields.careeropportunitiesExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/careers-details/${val.fields.slug}`} className="see-more-link">View Job Details</Link>
                    </div>
                    </div>
                </div>
            )
        });

        return (
            <>
            <div className="container-fluid">
                    {this.state.careerspage.length === 0 ? (
                    <div align="center">
                        <img src={BlackLoading} alt="Loading..." />
                    </div>
                    ) : (
                    <div className="row">
                        {this.state.careerspage.map((item, index) => {
                        return (
                            <div key={index} className="col-lg-12">
                            <div className="text-left pt-2">
                                <h1 className="text-uppercase">{item.fields.careersTitle}</h1>
                                <h2 className="lead">{item.fields.careersShortDetails}</h2>
                            </div>

                            <div className="pt-2">
                                <div className="row">
                                <div className="col-lg-4">
                                    <img
                                    className="img-fluid"
                                    src={item.fields.careersImg.fields.file.url}
                                    alt="sam bridge careers"
                                    />
                                    
                                </div>
                                <div className="col-lg-8">
                                    <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                                        item.fields.careersDescription
                                    )}></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        );
                        })}
                    </div>
                    )}
            </div>
            
            <div className="container-fluid">
                <div className="row">
                    {CareersList}
                </div>
                {/* <br/><hr/>
                <div>For employment inquires please email: careers@sambridge.com</div> */}
            </div>
        </>
        )
    }
}

export default Careers;
