import React, { Component } from 'react'

class LandscapeForm extends Component {
    
    render() {
          
    return (
        <>

<form className="form-horizontal" action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSefwALPI8ZIxI2dn3YFcg0nuRpS4rMsws3GIZ6SEN70KmwnOQ/formResponse" method="POST">
  <div className="form-group">
    <label className="control-label col-sm-2" for="email">Name:</label>
    <div className="col-sm-10">
      <input type="text" className="form-control" id="name" placeholder="Enter name" name="entry.414167305" required/>
    </div>
  </div>
  <div className="form-group">
    <label className="control-label col-sm-2" for="email">Phone:</label>
    <div className="col-sm-10">
      <input type="number" className="form-control" id="phone" placeholder="Enter Phone number best to reach you" name="entry.213857062" required/>
    </div>
  </div>
  <div className="form-group">
    <label className="control-label col-sm-2" for="email">Email:</label>
    <div className="col-sm-10">
      <input type="email" className="form-control" id="email" placeholder="Enter email" name="entry.1313055754" required/>
    </div>
  </div>
  <div className="form-group">
    <label className="control-label col-sm-2" for="email">Address:</label>
    <div className="col-sm-10">
      <input type="text" className="form-control" id="address" placeholder="Enter address" name="entry.833034968" required/>
    </div>
  </div>
  <div className="form-group">
  <label>Please give us some more information about your project. Using the control or command key highlight all that apply.</label>
      <select multiple class="form-control" name="entry.1038966199">
        <option value="Total Landscape" jsname="ksKsZd">Total Landscape</option>
        <option value="New Home Construction" jsname="ksKsZd">New Home Construction</option>
        <option value="Garden Beds and Perennial Plantings" jsname="ksKsZd">Garden Beds and Perennial Plantings</option>
        <option value="Privacy Screening" jsname="ksKsZd">Privacy Screening</option>
        <option value="Foundation Planting" jsname="ksKsZd">Foundation Planting</option>
        <option value="Pool Area Planting" jsname="ksKsZd">Pool Area Planting</option>
        <option value="Wetlands Planting or Remediation" jsname="ksKsZd">Wetlands Planting or Remediation</option>
      </select>
  </div>
  <div className="form-group">
    <div className="col-sm-offset-2 col-sm-10">
      <button type="submit" id="submit" name="send" className="btn btn-primary" variant="outline-secondary">Submit</button>
    </div>
  </div>
</form>

        </>
    );
    };
}

export default LandscapeForm;

/*
<div className="checkbox">
        <label><input type="checkbox" value="Total Landscape" name="entry.1038966199_sentinel"/>Total Landscape</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="New Home Construction" name="entry.1038966199_sentinel"/>New Home Construction</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="Garden Beds and Perennial Plantings"/>Garden Beds and Perennial Plantings</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="Privacy Screening"/>Privacy Screening</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="Foundation Planting"/>Foundation Planting</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="Pool Area Planting"/>Pool Area Planting</label>
      </div>
      <div className="checkbox">
        <label><input type="checkbox" value="Wetlands Planting or Remediation"/>Wetlands Planting or Remediation</label>
      </div>
      */