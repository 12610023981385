import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import marked from "marked"; //to make font bold and headings


class FarmersmarketRotatingVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farmersmarketRotatingVendorspage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "farmersmarketRotatingVendors",
    }).then((entries) => {
      this.setState({ farmersmarketRotatingVendorspage: entries.items });
    });
  }

  //to make font bold and headings
  getParsedMarkdown(farmersmarketRotatingVAddress) {
    return {
      __html: marked(farmersmarketRotatingVAddress, { sanitize: true }),
    };
  }
  //to make font bold and headings - end

  render() {
    return (
      <div>
        {this.state.farmersmarketRotatingVendorspage.length === 0 ? (
          <div align="center">
            <img src={BlackLoading} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {this.state.farmersmarketRotatingVendorspage.map((item, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <div className="text-left pt-4">
                    <h1>{item.fields.farmersmarketRotatingVTitle}</h1>
                    {/* <p>{item.fields.farmersmarketDescription}</p> */}
                    <div dangerouslySetInnerHTML = {this.getParsedMarkdown(
                            item.fields.farmersmarketRotatingVAddress
                        )}>
                  </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

    );
  }
}

export default FarmersmarketRotatingVendors;
