import React, { Component } from 'react'
import Client from '../Client'
import {Link} from 'react-router-dom';

class HomeArticles extends Component {
    constructor(props) {
        super(props)
        this.state = {
             landscapepage:[],
             ourplantspage:[],
             howtoplantpage:[],
             premiergardencarepage:[],
             mulchessoilpage:[],
             ourguaranteepage:[],
             locallygrownpage:[],
             fallthingspage:[],
             potterygardenpage:[],
             Christmaspage:[],
             containerdesignpage:[],
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'landscapeDesign',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({landscapepage: entries.items})
        })
        Client.getEntries({
            'content_type': 'ourplants',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({ourplantspage: entries.items})
        })
        Client.getEntries({
            'content_type': 'howtoplant',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({howtoplantpage: entries.items})
        })
        Client.getEntries({
            'content_type': 'premiergardencare',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({premiergardencarepage: entries.items})
        })
        Client.getEntries({
            'content_type': 'mulchessoilamendments',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({mulchessoilpage: entries.items})
        })
        Client.getEntries({
            'content_type': 'ourguarantee',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({ourguaranteepage: entries.items})
        })
        Client.getEntries({
            'content_type': 'locallygrown',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({locallygrownpage: entries.items})
        })
        Client.getEntries({
            'content_type': 'fallthings',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({fallthingspage: entries.items})
        })
        Client.getEntries({
            'content_type': 'potterygarden',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({potterygardenpage: entries.items})
        })
        Client.getEntries({
            'content_type': 'christmas',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({Christmaspage: entries.items})
        })
        Client.getEntries({
            'content_type': 'containerdesign',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({containerdesignpage: entries.items})
        })
    }

   
    render() {
        let LandscapeList = this.state.landscapepage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design`}>
                            <img src={val.fields.landscapeDesignImg.fields.file.url} className="img-fluid" alt="landscape design" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design`}>{val.fields.landscapeDesignTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let ourplantslist = this.state.ourplantspage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/our-plants`}>
                            <img src={val.fields.ourplantsImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/our-plants`}>{val.fields.ourplantTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let HowToPlantList = this.state.howtoplantpage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/how-to-plant`}>
                            <img src={val.fields.howtoplantImg.fields.file.url} className="img-fluid" alt="landscape design" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/how-to-plant`}>{val.fields.howtoplantTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });
        let PremierGardenCareList = this.state.premiergardencarepage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/premier-garden-care`}>
                            <img src={val.fields.premiergardencareImg.fields.file.url} className="img-fluid" alt="premier-garden-care" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/premier-garden-care`}>{val.fields.premiergardencareTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let MulchesSoilAmendmentsList = this.state.mulchessoilpage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/mulches-soil-amendments`}>
                            <img src={val.fields.mulchessoilamendmentsImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/mulches-soil-amendments`}>{val.fields.mulchessoilamendmentsTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let OurGuaranteeList = this.state.ourguaranteepage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/our-guarantee`}>
                            <img src={val.fields.ourguaranteeImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/our-guarantee`}>{val.fields.ourguaranteeTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let LocallyGrownList = this.state.locallygrownpage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/locally-grown`}>
                            <img src={val.fields.locallygrownImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/locally-grown`}>{val.fields.locallygrownTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let FallThingsList = this.state.fallthingspage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/all-the-fall-things`}>
                            <img src={val.fields.fallthingsImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/all-the-fall-things`}>{val.fields.fallthingsTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let PolleryGardenList = this.state.potterygardenpage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/pottery-garden`}>
                            <img src={val.fields.potterygardenImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/pottery-garden`}>{val.fields.potterygardenTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let ChristmasList = this.state.Christmaspage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/christmas`}>
                            <img src={val.fields.christmasImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/christmas`}>{val.fields.christmasTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        let ContainerDesignList = this.state.containerdesignpage.map((val, i) => {
            return(
                <div className="col-lg-4 pt-2" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/container-design`}>
                            <img src={val.fields.containerdesignImg.fields.file.url} className="img-fluid" alt="landscape design" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <strong>
                        <Link to={`${process.env.PUBLIC_URL}/container-design`}>{val.fields.containerdesignTitle}</Link>
                        </strong>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        return (
            <div>
            <div className="container-fluid">
                <div className="row">
                    {LandscapeList}
                    {HowToPlantList}
                    {PremierGardenCareList}
                    {MulchesSoilAmendmentsList}
                    {OurGuaranteeList}
                    {LocallyGrownList}
                    {FallThingsList}
                    {PolleryGardenList}
                    {ChristmasList}
                    {ContainerDesignList}
                    {ourplantslist}
                </div>           
            </div>
            </div>
        )
    }
}

export default HomeArticles;
