import React, { Component } from 'react'
import Client from '../Client'
//import BlackLoading from '../images/black-loader.gif'
//import marked from 'marked' //to make font bold and headings
import {Link} from 'react-router-dom';
import Categories from '../Components/Categories';

export class OurPlants extends Component {
    constructor(props) {
        super(props)
        this.state = {
             ourplantspage:[]
        }
    }

    componentDidMount(){
        Client.getEntries({
            'content_type': 'ourplants',
            'order': 'sys.createdAt'

        }).then((entries)=>{
            this.setState({ourplantspage: entries.items})
        })
    }

   
    render() {
        let Datalist = this.state.ourplantspage.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-4 col-12" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/our-plants-details/${val.fields.slug}`}>
                            <img src={val.fields.ourplantsImg.fields.file.url} className="img-fluid" alt="our plants" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h1>
                        <Link to={`${process.env.PUBLIC_URL}/our-plants-details/${val.fields.slug}`}>{val.fields.ourplantTitle}</Link>
                        </h1>
                        {/* <p className="subtitle">{val.fields.bookExcerpt}</p>
                        <Link to={`${process.env.PUBLIC_URL}/landscape-design-details/${val.fields.slug}`} className="see-more-link">SEE MORE</Link> */}
                    </div>
                    </div>
                </div>
            )
        });

        return (
            <div>
            <div><Categories/></div>
            <div className="container-fluid pt-2">
                <div className="row">
                    {Datalist}
                </div>           
            </div>
            </div>
        )
    }
}

export default OurPlants
