import React, { Component } from 'react'
import Categories from '../Components/Categories';
import HomeArticles from './HomeArticles';

class AllArticles extends Component {
   
   
    render() {

        return (
            <div>
            <div><Categories/></div>
            <div className="container-fluid">
                <HomeArticles/>          
            </div>
            </div>
        )
    }
}

export default AllArticles
