import React, { Component } from 'react'
//import HomeModal from '../Components/HomeModal'
import SlideShow from '../Components/SlideShow'
import HomeTwoAds from './HomeTwoAds'
//import HomeVideo from './HomeVideo';
import './Main.css'

class Home extends Component {


    render() {
        return (
            <div className="container-fluid">
                <SlideShow />
                <HomeTwoAds/>
                {/*<HomeModal/>*/}
                {/*<div>
                    <h1 style={{"text-align":"center"}}>Looking for a Unique Gift Ideas?</h1>
                    <HomeVideo/>
                </div>*/}
            </div>
        );
    }
}

export default Home
