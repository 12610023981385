import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class HomeTwoAds extends Component {


    render() {
        return (
            <>
            <div>
                <div className="online-store-box">
                    <h1>SHOP ONLINE</h1>
                    <p>Curbside and Local Delivery available.</p>
                    <button className="btn btn-primary"><a href="https://store.sambridge.com/" target="_blabk">CLICK SHOP</a></button>
                </div>
                
            </div>
            <div>
            <div className="landscapedesign-box">
                <h1>LANDSCAPE DESIGN</h1>
                    <p>Landscapes from Start to Finish &amp; Everything In-between</p>
                <button className="btn btn-primary"><Link to="/landscape-design-installation">CLICK HERE </Link></button>
                </div>

            </div>
            </>
        );
    }
}

export default HomeTwoAds
