import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import {Link} from 'react-router-dom';

class TreesShrubsGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TreesShrubsGallerypage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "treesShrubsGallery",
      'order': 'sys.createdAt'
    }).then((entries) => {
      this.setState({ TreesShrubsGallerypage: entries.items});
    });
  }

  redirectToTarget=()=>{
    this.props.history.push('/trees-shrubs')
  }


  render() {
    return (
      <>
      <div className="container-fluid text-left">
        <h1 className="text-uppercase">Trees &amp; Shrubs GALLERY</h1>
      </div>
      
      <div>
        {this.state.TreesShrubsGallerypage.length === 0 ?
          <div><img src={BlackLoading} alt="Loading..." /></div> :
              <div className="grid">
                  {this.state.TreesShrubsGallerypage.map((item, num=0) => {
                      num = num + 1
                      return (
                          <div key={num} className="col-md-4 col-12">
                              <input type="checkbox" id={num} />
                              <label htmlFor={num} className="lightbox"><img src={item.fields.treesShrubsGalleryImg.fields.file.url} alt="sam bridge trees and shrubs photos" /></label>
                              <label htmlFor={num}><img src={item.fields.treesShrubsGalleryImg.fields.file.url} alt="sam bridge trees and shrubs photos" /></label>
                              {/* <p className="text-center">{item.fields.treesShrubsGalleryTitle}</p> */}
                          </div>
                      )
                  })}
            </div>
      }

      </div>
      <div className="container-fluid">
      <p><Link to="#" onClick={this.redirectToTarget}><button className="btn btn-primary">Back to Trees Shrubs</button></Link></p>
      </div>
    </>
    );
  }
}

export default TreesShrubsGallery;
