import React, { Component } from 'react'
//import {Link} from 'react-router-dom'
import Client from '../Client'
import marked from 'marked' //to make font bold and headings
import BlackLoading from '../images/black-loader.gif'
//import stateImage from '../images/defaultimg.jpg';

class PagesDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             allpages:[],
             stateImage: false
        }
    }

    componentDidMount(){
        const slug = this.props.match.params.slug
        if(slug){
            Client.getEntries({
                'content_type': 'pages', 
                'fields.slug': slug
    
            }).then((entries)=>{
                this.setState({allpages: entries.items[0]})
            })

        }
        
    }

    //to make font bold and headings
    getParsedMarkdown(pageDescription){
        return{
            __html:marked(pageDescription, {sanitize:false})
        }
    }
    //to make font bold and headings - end
    
    redirectToTarget=()=>{
        this.props.history.push('/pages')
    }
    render() {
        return (
          <div className="container-fluid">
            {this.state.allpages.length === 0 ? (
                <div align="center"><img src={BlackLoading} alt="Loading..." /></div> ) : (
            <div className="row">
                      <div className="col-lg-12">
                        <div className="text-left pt-2">
                          <h1 className="text-uppercase">{this.state.allpages.fields.pageTitle}</h1>
                          <h2 className="lead">{this.state.allpages.fields.pageShortDetails}</h2>
                        </div>

                        <div className="pt-2">
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="img-fluid"
                                // src={this.state.allpages.fields.pageMedia.fields.file.url}
                                src={this.state.stateImage ? require('../images/defaultimg.jpg') : (this.state.allpages.fields.pageMedia.fields.file.url)}
                                alt={this.state.allpages.fields.pageTitle}
                              />
                              
                            </div>
                            <div className="col-lg-8">
                              <div style={{textAlign:'justify'}} dangerouslySetInnerHTML = {this.getParsedMarkdown(
                                  this.state.allpages.fields.pageDescription
                              )}></div>
                            </div>
                          </div>
                        </div>
                      </div>
            </div>
            )}
          </div>
        )
    }
}

export default PagesDetails;
