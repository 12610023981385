import React, { Component } from 'react'
import Client from '../Client'
import BlackLoading from '../images/black-loader.gif';
import {Carousel} from 'react-bootstrap';

class LandscapeDesignFlashImages extends Component {
  constructor(props) {
    super(props)
    this.state = {
         landscapeflashimgpage:[]
    }
}

componentDidMount(){
    Client.getEntries({
        'content_type': 'landscapeDesignFlashImages'

    }).then((entries)=>{
        this.setState({landscapeflashimgpage: entries.items})
    });
}


render() {
  return (
    <>
        {this.state.landscapeflashimgpage.length === 0 ?
              <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
        <Carousel fade={false}>
          
          {this.state.landscapeflashimgpage.map((item, index) => {
            return(
            <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.fields.landscapeDesignFlashImagesImg.fields.file.url}
              alt="sam bridge landscape design slide"
            />
            <Carousel.Caption>
                <span className="slidefont">{item.fields.landscapeDesignFlashImagesTitle}</span>
            </Carousel.Caption>
            </Carousel.Item>
                  )
                  })}
        </Carousel>
        }

    </>
    )
  }
}

export default LandscapeDesignFlashImages;


