import React, { Component } from 'react'
import Client from '../Client'
import BlackLoading from '../images/black-loader.gif';
import {Carousel} from 'react-bootstrap';

export class SlideShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
         homebanner:[]
    }
}

componentDidMount(){
    Client.getEntries({
        'content_type': 'slideshowImages'

    }).then((entries)=>{
        this.setState({homebanner: entries.items})
    })
}

// setClass = (index) =>{
//   if(index === 0){
//     return "carousel-item active"
//   } else {
//     return "carousel-item"
//   }
// }

render() {
  return (
    <>
        {this.state.homebanner.length === 0 ?
              <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
        <Carousel fade={false}>
          
          {this.state.homebanner.map((item, index) => {
            return(
            <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.fields.slideshowImage.fields.file.url}
              alt="First slide"
            />
            <Carousel.Caption>
                <span className="slidefont">{item.fields.slideshowTitle}</span> <br></br>
                <a href={item.fields.slideshowButton} target="_parent"><span style={{fontSize: "21px", color: "white", backgroundColor: "#007bff"}}>{item.fields.slideshowButtonName}</span></a>
            </Carousel.Caption>
            </Carousel.Item>
                  )
                  })}
        </Carousel>
        }



      {/* {this.state.homebanner.length === 0 ?
      <div align="center"><img src={BlackLoading} alt="Loading..." /></div> :
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
        <div className="carousel-inner">
            {this.state.homebanner.map((item, index) => {
              return(
          <div key={index} className={this.setClass(index)}>
            <div className="carousel-caption">
              <span className="slidefont">{item.fields.slideshowTitle}</span>
            </div>
            <img src={item.fields.slideshowImage.fields.file.url} class="d-block w-100" alt="..." />
            
          </div>
          )
          })}
        </div>

        <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      } */}

    </>
    )
  }
}

export default SlideShow


