import React, { Component } from 'react'
import {Button, FormControl, InputGroup, Form} from 'react-bootstrap';



class EmailSignUp extends Component {
    
    render() {
          
    return (
        <>
            <div className="center" >
                <span>Keep in Touch:</span>
                <Form action="https://docs.google.com/forms/u/8/d/e/1FAIpQLScwp2JvGwuV7DpOM8jizXLoQs6vXbOGfP3D2LOdvtPDKDvqwA/formResponse" method="POST">
                <InputGroup className="mb-3">
                    <FormControl
                    type="email"
                    name="entry.440994007" 
                    placeholder="Enter Email Address"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                    required
                    />
                    <InputGroup.Append>
                    <Button variant="outline-secondary" type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary">SUBSCRIBE!</Button>
                    </InputGroup.Append>
                </InputGroup>
                </Form>

            </div>
        </>
);
}
}

export default EmailSignUp;