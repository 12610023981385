import React, { Component } from "react";
import Client from "../Client";
import BlackLoading from "../images/black-loader.gif";
import {Link} from 'react-router-dom';

class LandscapeDesignGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landscapeDesignGallerypage: [],
    };
  }

  componentDidMount() {
    Client.getEntries({
      content_type: "landscapeDesignGallery",
      //'order': 'sys.createdAt'
    }).then((entries) => {
      this.setState({ landscapeDesignGallerypage: entries.items});
    });
  }

  redirectToTarget=()=>{
    this.props.history.push('/landscape-design-installation')
}


  render() {
    return (
      <>
      <div className="container-fluid text-left">
        <h1>LANDSCAPE DESIGN GALLERY</h1>
      </div>
      <div>
        {this.state.landscapeDesignGallerypage.length === 0 ?
          <div><img src={BlackLoading} alt="Loading..." /></div> :
              <div className="grid">
                  {this.state.landscapeDesignGallerypage.map((item, num=0) => {
                      num = num + 1
                      return (
                          <div key={num} className="col-md-4 col-12">
                              <input type="checkbox" id={num} />
                              <label htmlFor={num} className="lightbox"><img src={item.fields.landscapeDesignPhoto.fields.file.url} alt="sam bridge landscape design photos" /></label>
                              <label htmlFor={num}><img src={item.fields.landscapeDesignPhoto.fields.file.url} alt="sam bridge landscape design photos" /></label>
                              {/* <p className="text-center">{item.fields.landscapeDesignGalleryTitle}</p> */}
                          </div>
                      )
                  })}
            </div>
      }

      </div>
      <div className="container-fluid">
      <p><button className="btn btn-primary"><Link to="#" onClick={this.redirectToTarget}>Back to Landscape Design </Link></button></p>
      </div>
    </>
    );
  }
}

export default LandscapeDesignGallery;
